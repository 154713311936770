<template>
  <div>
    <weightedButtons
      :selected="selected"
      :items="testStatus"
      :totalItems="total"
      :enableTotal="true"
      :enableBack="false"
      @selectedItem="selectedItem"
      :key="currentTable.id"
    ></weightedButtons>
    <standbyDialog :localLoadingDialog="loading"></standbyDialog>
  </div>
</template>
<script>
import standbyDialog from "@/commonComponents/standbyDialog.vue";
import weightedButtons from "@/commonComponents/weightedButtons.vue";

export default {
  props: ["currentTable", "result"],
  components: { weightedButtons, standbyDialog },
  data() {
    return { /* testStatus: {},*/ selected: [], loading: false };
  },
  created() {
    if (this.currentTable.online) {
      this.selected = this.currentTable.online.split(",");
      //this.selectedItem(this.selected);
    }
    //  this.$nextTick(function () {
    // this.getStatusObject();
    //  });
    // this.unsubscribe = this.$store.subscribe((mutation) => {
    //   if (mutation.type === "tableChanged") {
    //     // this.getStatusObject();
    //     this.$nextTick(function () {
    //       this.selectedItem(this.selected);
    //     });
    //   }
    // });
  },
  // beforeDestroy() {
  //   this.unsubscribe();
  // },
  watch: {
    "currentTable.online": function (val) {
      if (val) {
        this.selected = val.split(",");
      } else {
        this.selected = [];
      }
    },
    // "currentTable.currentItems": function () {
    //   this.getStatusObject();
    //   // this.selectedItem(this.selected)
    // },
  },
  computed: {
    total() {
      let total = 0;
      if (typeof this.currentTable.statusStatisics != "undefined") {
        total = this.currentTable.statusStatisics.total;
      }
      return total;
    },
    testStatus() {
      // let itemsLength = this.currentTable.dataCount;
      let testStatus = [
        { name: "Online", color: "ok", index: "1" },
        {
          name: "Warning",
          color: "testStatusWarning",
          index: "2",
        },
        { name: "Offline", color: "testStatusError", index: "0" },
      ];

      let stats = this.currentTable.statusStatisics;
      if (typeof stats != "undefined") {
        testStatus.forEach((info) => {
          info["count"] = 0;
          if (typeof stats[info.index] != "undefined") {
            info["count"] = stats[info.index];
          }
        });
      }
      // }
      // this.testStatus = testStatus;
      return testStatus;
    },
  },
  methods: {
    filterStatus(source, status) {
      var tStatus = status;
      return source.filter(function (el) {
        return el.n_status == tStatus;
      });
    },
    filterStatusMulti(source, selected) {
      return source.filter(function (el) {
        return selected.includes(el.n_status);
      });
    },
    selectedItem(selected) {
      this.selected = selected;
     // this.loading = true;
      //let caller = this;
      let regex = /(online=).*?(&|$)/;
      let newUrl;
      let shortURL;
      if (this.currentTable.tableUrl.match(regex) != null) {
        newUrl = this.currentTable.tableUrl.replace(
          regex,
          "$1" + selected.join(",") + "$2"
        );

        shortURL = this.currentTable.tableShortUrl.replace(
          regex,
          "$1" + selected.join(",") + "$2"
        );
      } else {
        newUrl = this.currentTable.tableUrl + "&online=" + selected.join(",");
        shortURL =
          this.currentTable.tableShortUrl + "&online=" + selected.join(",");
      }
      history.pushState({}, null, shortURL);
       this.$set(this.currentTable,'tableUrl',newUrl)
      this.$set(this.currentTable,'tableShortUrl',shortURL)
       this.$store.commit("tableChanged", this.currentTable);
      // this.frameworkAxiosRequest({
      //   method: "post",
      //   url: newUrl,
      //   //data: { status: selected.join(",") },
      // })
      //   .then(function (response) {
      //     caller.$store.commit("tableChanged", response.data.result.table);
      //     caller.loading = false;
      //   })
      //   .catch(function (response) {
      //     //handle error
      //     console.log(response);
      //     caller.loading = false;
      //   });
    },
  },
};
</script>
